// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';

(function() {

    // Home banner swiper
    const homeBannerSwiper = new Swiper('#home-banner-slider', {
        modules: [Navigation, Autoplay],
        // autoplay: {
        //     delay: 2000,
        // }
        loop: true,
    });

    // Products swiper
    const productsSwiper = new Swiper('#products-slider', {
        modules: [Navigation, Autoplay],
        autoplay: {
            delay: 2500,
        },
        loop: true,
        loopedSlides: 2,
        navigation: {
            nextEl: '#products-navigation .next',
            prevEl: '#products-navigation .prev',
        },
        slidesPerView: 1,
        speed: 1000,
        breakpoints: {
            992: {
                slidesPerView: 2,
                spaceBetween: 60,
            }
        }
    });

})();